import React from "react"

import Layout from "../components/UI/Layout"
import Seo from "../components/Seo"
import Navbar from "../components/UI/Navbar"
import FaqSection from "../components/FaqSection"

export default function FaqPage() {
  return (
    <Layout>
      <Navbar />
      <Seo
        title="FAQs"
        description="Have questions about a water feature? Get them answered here by water feature experts at Andreatta Waterscapes in Central Point, Oregon."
      />
      <FaqSection />
    </Layout>
  )
}
