import React from "react"
import { Link } from "gatsby"
import { makeStyles, withStyles } from "@material-ui/core/styles"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import MuiAccordion from "@material-ui/core/Accordion"
import MuiAccordionSummary from "@material-ui/core/AccordionSummary"
import MuiAccordionDetails from "@material-ui/core/AccordionDetails"
import Button from "@material-ui/core/Button"
import Container from "@material-ui/core/Container"
import { BsPlus } from "react-icons/bs"
import { BiMinus } from "react-icons/bi"

const Accordion = withStyles({
  root: {
    fontFamily: "Open Sans, sans-serif",
    // border: "1px solid rgba(0, 0, 0, .125)",
    borderRadius: 0,
    boxShadow: "none",
    width: "100%",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},
})(MuiAccordion)

const AccordionSummary = withStyles({
  root: {
    fontFamily: "Open Sans, sans-serif",
    // backgroundColor: "rgba(0, 0, 0, .03)",
    // borderBottom: "1px solid rgba(0, 0, 0, .125)",
    marginBottom: -1,
    minHeight: 56,
    width: "100%",
    "&$expanded": {
      minHeight: 56,
      borderRadius: "0px 0px 0px 0px",
      backgroundColor: "rgba(255,255,255,.05)",
    },
  },
  content: {
    "&$expanded": {
      margin: "12px 0",
      backgroundColor: "rgba(255,255,255,.0)",
    },
  },
  expandIcon: {
    order: -1,
    "&$expanded": {
      backgroundColor: "rgba(255,255,255,.0)",
      clipPath: "circle(20px at center)",
    },
  },
  expanded: {
    backgroundColor: "rgba(255,255,255,.05)",
  },
})(MuiAccordionSummary)

const AccordionDetails = withStyles(theme => ({
  root: {
    borderRadius: "0px 0px 0px 0px",
    padding: theme.spacing(2),
    // "&$expanded": {
    //   backgroundColor: "#f0f4fd",
    // },
  },
}))(MuiAccordionDetails)

const useStyles = makeStyles(theme => ({
  ctr: {
    paddingLeft: "4em",
    paddingRight: "4em",
  },
  h1: {
    paddingTop: "5.875rem",
    fontSize: "2.9375rem",
    // fontSize: "1.9375rem",
    fontWeight: 400,
    [theme.breakpoints.down("md")]: {
      marginTop: "2.5rem",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "2.25rem",
      marginTop: "2rem",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "2rem",
      lineHeight: "2.75rem",
    },
  },
  accordionCtr: {
    paddingTop: "2.1875rem",
    maxWidth: "40%",
    marginLeft: "auto",
    marginRight: "auto",
    [theme.breakpoints.down("lg")]: {
      maxWidth: "45%",
    },
    [theme.breakpoints.down("md")]: {
      maxWidth: "60%",
    },
    [theme.breakpoints.down("sm")]: {
      maxWidth: "80%",
    },
    [theme.breakpoints.down("xs")]: {
      maxWidth: "95%",
    },
  },
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: theme.spacing(12),
    marginBottom: theme.spacing(12),
    [theme.breakpoints.down("xs")]: {
      padding: "0em 1.25em",
    },
  },
  button: {
    border: "4px solid currentColor",
    borderRadius: 0,
    height: "auto",
    padding: theme.spacing(2, 10),
  },
  link: {
    fontSize: "1.1875rem",
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  h4: {
    fontFamily: "Open Sans, Work Sans",
    fontSize: "2.25rem",
    fontWeight: 500,
    [theme.breakpoints.down("xs")]: {
      fontSize: "1.25rem",
    },
  },
  h5: {
    fontFamily: "Open Sans, Work Sans",
    marginTop: "-2rem",
    fontSize: "1.875rem",
    // color: theme.palette.common.muted,
    fontWeight: 500,
    paddingBottom: "1.5rem",
  },
  internalLink: {
    ...theme.typography.body1,
    color: "#da7235",
    "&:hover, :visited, :active": {
      color: "#da7235",
    },
  },
}))

export default function FaqSection() {
  const classes = useStyles()
  // const [expanded, setExpanded] = React.useState("panel1")
  const [expanded, setExpanded] = React.useState(null)

  const handleChange = panel => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false)
  }

  return (
    <Grid container justifyContent="center">
      <Grid item xs={12}>
        <Typography
          variant="h1"
          align="center"
          className={classes.h1}
          gutterBottom
        >
          Resources
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Grid
          container
          justifyContent="center"
          className={classes.accordionCtr}
        >
          <Accordion
            expanded={expanded === "panel1"}
            onChange={handleChange("panel1")}
          >
            <AccordionSummary
              expandIcon={
                expanded === "panel1" ? (
                  <BiMinus size={30} />
                ) : (
                  <BsPlus size={30} />
                )
              }
              aria-controls="panel1d-content"
              id="panel1d-header"
            >
              <Typography
                style={{
                  paddingLeft: expanded ? "1.3em" : "1em",
                  paddingTop: 2,
                  fontWeight: 600,
                  color: "#da7235",
                }}
              >
                What are the best algae control methods?
              </Typography>
            </AccordionSummary>
            <AccordionDetails
              style={{ backgroundColor: expanded && "rgba(255,255,255,.05)" }}
            >
              <Grid container direction="column" className={classes.ctr}>
                <Grid item>
                  <Typography>
                    Plant heavily (especially in full sun), use a skimmer to bag
                    debris, add bacteria, don’t overfeed fish, fix leaks,
                    control run-off, but most of all relax. Algae is a natural
                    part of the ponds ecosystem. A new pond may turn green until
                    the biological filter has a chance to grow sufficient
                    bacteria (typically 3-6 weeks). Be patient as the pond finds
                    it’s natural balance.
                  </Typography>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel2"}
            onChange={handleChange("panel2")}
          >
            <AccordionSummary
              expandIcon={
                expanded === "panel2" ? (
                  <BiMinus size={30} />
                ) : (
                  <BsPlus size={30} />
                )
              }
              aria-controls="panel2d-content"
              id="panel2d-header"
            >
              <Typography
                style={{
                  paddingLeft: expanded ? "1.3em" : "1em",
                  paddingTop: 2,
                  fontWeight: 600,
                  color: "#da7235",
                }}
              >
                What role does bacteria play in keeping my pond healthy?
              </Typography>
            </AccordionSummary>
            <AccordionDetails
              style={{ backgroundColor: expanded && "rgba(255,255,255,.05)" }}
            >
              <Grid container direction="column" className={classes.ctr}>
                <Grid item>
                  <Typography>
                    Naturally occurring, safe, non-toxic bacteria’s assist in
                    breaking down organic matter thereby minimizing the release
                    of nutrients & toxins into the water.
                  </Typography>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel3"}
            onChange={handleChange("panel3")}
          >
            <AccordionSummary
              expandIcon={
                expanded === "panel3" ? (
                  <BiMinus size={30} />
                ) : (
                  <BsPlus size={30} />
                )
              }
              aria-controls="panel3d-content"
              id="panel3d-header"
            >
              <Typography
                style={{
                  paddingLeft: expanded ? "1.3em" : "1em",
                  paddingTop: 2,
                  fontWeight: 600,
                  color: "#da7235",
                }}
              >
                What kind of liner do you use?
              </Typography>
            </AccordionSummary>
            <AccordionDetails
              style={{ backgroundColor: expanded && "rgba(255,255,255,.05)" }}
            >
              <Grid container direction="column" className={classes.ctr}>
                <Grid item>
                  <Typography>
                    45 mil EPDM. It is extremely flexible, durable, and has a 20
                    year manufacturers warranty.
                  </Typography>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel4"}
            onChange={handleChange("panel4")}
          >
            <AccordionSummary
              expandIcon={
                expanded === "panel4" ? (
                  <BiMinus size={30} />
                ) : (
                  <BsPlus size={30} />
                )
              }
              aria-controls="panel4d-content"
              id="panel4d-header"
            >
              <Typography
                style={{
                  paddingLeft: expanded ? "1.3em" : "1em",
                  paddingTop: 2,
                  fontWeight: 600,
                  color: "#da7235",
                }}
              >
                What is the best size pump for my waterfall?
              </Typography>
            </AccordionSummary>
            <AccordionDetails
              style={{ backgroundColor: expanded && "rgba(255,255,255,.05)" }}
            >
              <Grid container direction="column" className={classes.ctr}>
                <Grid item>
                  <Typography>
                    Pump size will vary depending on height requirements and the
                    amount of flow desired. We like 1200-1500 GPH per foot of
                    stream width.
                  </Typography>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel5"}
            onChange={handleChange("panel5")}
          >
            <AccordionSummary
              expandIcon={
                expanded === "panel5" ? (
                  <BiMinus size={30} />
                ) : (
                  <BsPlus size={30} />
                )
              }
              aria-controls="panel5d-content"
              id="panel5d-header"
            >
              <Typography
                style={{
                  paddingLeft: expanded ? "1.3em" : "1em",
                  paddingTop: 2,
                  fontWeight: 600,
                  color: "#da7235",
                }}
              >
                How do I keep predators from eating my fish?
              </Typography>
            </AccordionSummary>
            <AccordionDetails
              style={{ backgroundColor: expanded && "rgba(255,255,255,.05)" }}
            >
              <Grid container direction="column" className={classes.ctr}>
                <Grid item>
                  <Typography>
                    Cats & Raccoons are not swimming predators, by building your
                    pond 8’ wide and installing a house for the fish you cut
                    down on your chances of becoming a victim.
                  </Typography>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel6"}
            onChange={handleChange("panel6")}
          >
            <AccordionSummary
              expandIcon={
                expanded === "panel6" ? (
                  <BiMinus size={30} />
                ) : (
                  <BsPlus size={30} />
                )
              }
              aria-controls="panel6d-content"
              id="panel6d-header"
            >
              <Typography
                style={{
                  paddingLeft: expanded ? "1.3em" : "1em",
                  paddingTop: 2,
                  fontWeight: 600,
                  color: "#da7235",
                }}
              >
                Do we need to shut our waterfall off during the winter?
              </Typography>
            </AccordionSummary>
            <AccordionDetails
              style={{ backgroundColor: expanded && "rgba(255,255,255,.05)" }}
            >
              <Grid container direction="column" className={classes.ctr}>
                <Grid item>
                  <Typography>
                    During the winter the fish hibernate and we keep our
                    waterfalls running year round. Most water plants are
                    perennials and will return in the spring.
                  </Typography>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel7"}
            onChange={handleChange("panel7")}
          >
            <AccordionSummary
              expandIcon={
                expanded === "panel7" ? (
                  <BiMinus size={30} />
                ) : (
                  <BsPlus size={30} />
                )
              }
              aria-controls="panel7d-content"
              id="panel7d-header"
            >
              <Typography
                style={{
                  paddingLeft: expanded ? "1.3em" : "1em",
                  paddingTop: 2,
                  fontWeight: 600,
                  color: "#da7235",
                }}
              >
                How much does it cost to build a water feature?
              </Typography>
            </AccordionSummary>
            <AccordionDetails
              style={{ backgroundColor: expanded && "rgba(255,255,255,.05)" }}
            >
              <Grid container direction="column" className={classes.ctr}>
                <Grid item>
                  <Typography>
                    Each pond is unique, please call us at (541) 664-4511.
                  </Typography>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel8"}
            onChange={handleChange("panel8")}
          >
            <AccordionSummary
              expandIcon={
                expanded === "panel8" ? (
                  <BiMinus size={30} />
                ) : (
                  <BsPlus size={30} />
                )
              }
              aria-controls="panel8d-content"
              id="panel8d-header"
            >
              <Typography
                style={{
                  paddingLeft: expanded ? "1.3em" : "1em",
                  paddingTop: 2,
                  fontWeight: 600,
                  color: "#da7235",
                }}
              >
                Where can I see examples of your past work?
              </Typography>
            </AccordionSummary>
            <AccordionDetails
              style={{ backgroundColor: expanded && "rgba(255,255,255,.05)" }}
            >
              <Grid container direction="column" className={classes.ctr}>
                <Grid item>
                  <Typography>
                    Head on over to our{" "}
                    <Link
                      className={classes.internalLink}
                      to="/inspiration-gallery/"
                    >
                      Inspiration Gallery,
                    </Link>{" "}
                    <Link className={classes.internalLink} to="/outdoor-living/">
                      Outdoor Living Gallery,
                    </Link>{" "}
                    or{" "}
                    <Link className={classes.internalLink} to="/projects/">
                      Project Showcase!
                    </Link>
                  </Typography>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Container className={classes.root} component="section">
          <Typography variant="h5" className={classes.h5} align="center">
            Still have questions?
          </Typography>
          <Button
            className={classes.button}
            color="secondary"
            component={Link}
            to="/contact/"
          >
            <Typography
              variant="h4"
              component="span"
              color="secondary"
              align="center"
              className={classes.h4}
            >
              Contact Us
            </Typography>
          </Button>
          <Typography variant="subtitle1" className={classes.link}>
            We are here to help. Get in touch!
          </Typography>
        </Container>
      </Grid>
    </Grid>
  )
}
